import React, { Component } from "react"
import Slider from "react-slick"
import Testimonial from "./testimonial";
import IS_CORPORATE from '../../config/siteConfig';

class Testimonials extends Component {
  render() {
    const { data } = this.props
    let testimonials =  IS_CORPORATE ? data:data.filter(k=>k.node.data.show_on_ventures_page)
    
    const settings = {
      useTransform: true,
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 9000,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      rows: 1,
      fade: true,
      adptiveHeight: true
    }

    return (
      <Slider {...settings}>
        {testimonials &&
          testimonials.map(item => <Testimonial key={item.node.uid} data={item} />)}
      </Slider>
    )
  }
}

export default Testimonials
