import React from 'react';

const Categories = ({categories}) => {
    return (
        <>
            {categories.map(category => {
                return <span className="category" key={category}>{category}</span>
            })}
        </>
    );
};

export default Categories;