import React from 'react';
import LocalizedLink from '../localizedLink'
import Categories from './categories';
import Img from "gatsby-image"
import { Link } from 'gatsby';
import { LocaleContext } from '../layout'
import i18n from '../../../config/i18n';

const ListItem = ({node, categories,locale}) => {
    const i18nn = i18n[locale];
    return (
      <div className="container narrow gray">
      <div>
          <div className="post post__teaser two-cols">
        <div className="post__content">
          <div className="post__meta">
            <span className="post__date">
              {node.data.date && node.data.date}
            </span>{" "}
            {categories && <Categories categories={categories} />}
          </div>
          <Link className="title" to={`/${node.uid}`}>
            <h2>{node.data.title.text}</h2>
          </Link>
          <h3>{node.data.subtitle.text}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: node.data.teaser.html,
            }}
          />
          <LocalizedLink className="readMore" to={`/${node.uid}`}>
            {i18nn.readMore}
          </LocalizedLink>
        </div>

        <div className="post__image">
          {node.data.image_preview.localFile && (
            <Img
              alt={node.data.image_preview.alt}
              fluid={
                node.data.image_preview.localFile.childImageSharp.fluid
              }
            />
          )}
        </div>
          </div></div></div>
    )
};

export default ListItem;