import React from 'react';
import ListItem from './listItem';

const List = ({posts,locale}) => {
    
    return (
        <div className="posts">
            {posts.map(post => {
                let categories = false
                if (post.node.data.categories[0].category) {
                    categories = post.node.data.categories.map(c => c.category.document[0].data.name)
                }
                return <ListItem locale={locale} key={post.node.uid} node={post.node} categories={categories} />
            })}
        </div>
    );
};

export default List;