import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Animation from "../components/animation";
import { graphql } from "gatsby"
import { LocaleContext } from "../components/layout"
import List from "../components/List/list";
import Testimonials from "../components/Testimonial/testimonials";
import introAnimation from "../static/animations/bruecke.json"
import PortfolioContent from "../components/Portfolio/portfolioContent";
import IS_CORPORATE from '../config/siteConfig';



const IndexPage = ({ data: { homepage:page,homepageVentures:pageVentures, posts, portfolios, testimonials }, pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const homepage = IS_CORPORATE ? page : pageVentures;
  
  return (
    <>
      <SEO
         title={homepage.data.title.text}
         description={homepage.data.seo_homepage_beschreibung.text}
         keywords={homepage.data.seo_homepage_keywords.text ?homepage.data.seo_homepage_keywords.text.split(', ') :
         [`Gesundheit`, `Portfolio`, `Mehrwerte`]
        }
      />

      <section id="intro">
        <div className="container two-cols blue--gradient">
          <div className="intro__image">
            <Animation animation={introAnimation} loop={true} />
          </div>
          <div>
          {IS_CORPORATE ? <h1>
              Die<span>Brücken</span>Köpfe
            </h1>:<h1>{homepage.data.title.text }</h1>
}
            <h3>{homepage.data.subheadline.text}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: homepage.data.content.html,
              }}
            />
          </div>
        </div>
      </section>

      {testimonials && testimonials.edges && (
        <section id="testimonials">
          <div className="container">
            <Testimonials data={testimonials.edges} />
          </div>
        </section>
      )}

      <PortfolioContent
        title={'Portfolio'}
        text={homepage.data.portfolio_content.html}
        subtitle={homepage.data.portfolio_subheadline.text}
        data={portfolios} />

      {IS_CORPORATE &&
      <section id="posts">
          {posts && posts.edges && (
            <List locale={locale} posts={posts.edges} />
          )}
      </section>
      }
    </>
  )}


export default IndexPage


IndexPage.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired,
    portfolios: PropTypes.object,
    testimonials: PropTypes.object,
    homepage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
         query IndexPageQuery($locale: String!) {
           homepage: prismicHomepage(lang: { eq: $locale }) {
             data {
               title {
                 text
               }
               content {
                 html
               }
               subheadline {
                 text
               }
               footer {
                 html
               }
               portfolio_content {
                 html
               }
               portfolio_subheadline {
                 text
               }
               seo_homepage_beschreibung {
                text
              }
              seo_homepage_keywords {
                text
              }
             }
           }
           homepageVentures: prismicHomepageVentures(lang: { eq: $locale }) {
            data {
              title {
                text
              }
              content {
                html
              }
              subheadline {
                text
              }
              portfolio_content {
                html
              }
              portfolio_subheadline {
                text
              }
              seo_homepage_beschreibung {
               text
             }
             seo_homepage_keywords {
               text
             }
            }
          }
           posts: allPrismicPost(
             limit: 1
             sort: { fields: [data___date], order: DESC }
             filter: { lang: { eq: $locale } }
           ) {
             edges {
               node {
                 uid
                 data {
                   title {
                     text
                   }
                   subtitle {
                     text
                   }
                   teaser {
                     html
                   }
                   image {
                     alt
                     url
                     localFile {
                       childImageSharp {
                         fluid(maxWidth: 1400, quality: 90) {
                           ...GatsbyImageSharpFluid_withWebp
                         }
                       }
                     }
                   }
                   image_preview {
                     alt
                     url
                     localFile {
                       childImageSharp {
                         fluid(maxWidth: 800, quality: 90) {
                           ...GatsbyImageSharpFluid_withWebp
                         }
                       }
                     }
                   }
                   date(formatString: "DD.MM.YYYY")
                   categories {
                     category {
                       document {
                         data {
                           name
                         }
                       }
                     }
                   }
                 }
               }
             }
           }
           portfolios: allPrismicPortfolio(
             filter: { lang: { eq: $locale } }
             sort: { fields: [uid], order: ASC }
           ) {
             edges {
               node {
                 uid
                 data {
                   title {
                     text
                   }
                   content {
                     html
                   }
                   color
                   show_on_ventures_page
                   logo {
                     alt
                     url
                     localFile {
                       childImageSharp {
                         fluid(maxWidth: 1200, quality: 90) {
                           ...GatsbyImageSharpFluid_withWebp
                         }
                       }
                     }
                   }
                   url {
                     link_type
                     url
                     target
                   }
                 }
               }
             }
           }
           testimonials: allPrismicTestimonial(
             filter: { lang: { eq: $locale } }
           ) {
             edges {
               node {
                 uid
                 data {
                   name {
                     text
                   }
                   position {
                     text
                   }
                   show_on_ventures_page
                   company_name {
                     text
                   }
                   text {
                     html
                   }
                   company_logo {
                     alt
                     url
                     localFile {
                       childImageSharp {
                         fluid(maxWidth: 1200, quality: 90) {
                           ...GatsbyImageSharpFluid_withWebp
                         }
                       }
                     }
                   }
                   company_link {
                     link_type
                     url
                     target
                   }
                 }
               }
             }
           }
         }
       `

