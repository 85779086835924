import React, { Component } from "react"
import Img from "gatsby-image"

class Testimonial extends Component {
  render() {
    const item = this.props.data
    return (
      <div className={`Testimonial`}>
        <div className={`Testimonial__Info`}>
          <div className={`Testimonial__Info-Image`}>
            {(item.node.data.company_logo && item.node.data.company_logo.localFile && item.node.data.company_logo.localFile.childImageSharp) ? (
              <Img
                alt={item.node.data.alt || item.node.data.company_name.text}
                fluid={
                  item.node.data.company_logo.localFile.childImageSharp
                    .fluid
                }
              />
            ) : (
                <>
              {/*<Img
                alt={item.node.data.alt || item.node.data.company_name.text}
                src={item.node.data.company_logo.url}
              />*/}</>
            )}{" "}
          </div>
          <div className={`Testimonial__Info-Person`}>
            <strong>{item.node.data.name.text}</strong>
            {item.node.data.position.text}{" "}
            <a rel="follow" href={item.node.data.company_link.url} target="_blank">
              {item.node.data.company_name.text}
            </a>
          </div>
        </div>
        <div className="Testimonial__Content">
          <div className="paragraph"
            dangerouslySetInnerHTML={{
              __html: item.node.data.text.html,
            }}
          />
        </div>
      </div>
    )
  }
}

export default Testimonial
